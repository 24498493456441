/*
 * @Author: juan.liu
 * @LastEditors: juan.liu
 * @Date: 2022-08-04 17:59:50
 * @LastEditTime: 2022-08-09 17:46:33
 * @FilePath: /scrm-h5/src/api/modules/speechbank.js
 */
import { post } from '@/api/index'
const pre = '/dapi/b-access-layer/v1/access/'

/**
 * 话术库分组
*/
export const getALLGroupList = params => {
  return post(pre + 'knowledge-config-center/v1/group/list', params)
}

/**
 * 话术库分组
*/
export const getGroupList = params => {
  return post(pre + 'knowledge-config-center/v1/group/groupList', params)
}

/**
 * 话术库数据
*/
export const getGroupDetail = params => {
  return post(pre + 'knowledge-chatstratage-management/v1/content/chatStratage/list2C/standard', params)
}
/**
 * 话术库分享上报
*/
export const shareSpeechPoint = params => {
  return post(pre + 'content-statistic/script/statistics/add', params)
}
