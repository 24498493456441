<template>
  <div class="cont">
    <div class="title">企业话术</div>
    <div class="body">
      <search-bar ref="sarchBar" />
      <div class="list">
        <van-pull-refresh
          v-model="loading"
          class="vanList"
          @refresh="resetData"
        >
          <van-list
            v-model="loading"
            :offset="offset"
            :finished="finished"
            @load="getSriptLibrary"
          >
            <div
              v-for="(item, index) in list"
              :key="index"
              class="list_cont j-s"
            >
              <div class="img_l">
                <img class="lt-icon" src="@/assets/img/backup.png">
              </div>
              <div class="rt-cont">
                <div class="rt-title">{{ item.title }}</div>
                <div class="rt-text">
                 <div class="rt-content">{{ item.content }}</div>
                 <div class="tage-box"><van-tag type="warning" class="tag2">已发送:{{ item.sendTimes }}</van-tag></div>
                 </div>
                <img
                  class="edit_img"
                  src="@/assets/img/edit.png"
                  alt=""
                  @click="editSriptLibrary(item)"
                >
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>

    </div>
    <!-- 底部弹窗-发送话术库 -->
    <van-action-sheet v-model="editShow" title="话术库" style="min-height: 300px">
      <div class="edit_cont">
        <van-field
          v-model="cont"
          rows="6"
          autosize
          label="编辑"
          type="textarea"
          placeholder="请输入"
        />
        <div v-perm="'/sriptLibrary:send'" style="text-align: center">
          <van-button
            color="#000"
            plain
            @click="sendChatMessage"
          >发送</van-button>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import '@vant/touch-emulator'
import { getALLGroupList, getGroupDetail, shareSpeechPoint } from '@/api/modules/speechbank'
import wxSDK from '@/scripts/wxsdk'
import SearchBar from '../components/search-bar.vue'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
export default {
  components: {
    SearchBar
  },
  // 定义属性
  data() {
    return {
      list: [],
      loading: false,
      finished: true,
      pages: {
        pageIndex: 1,
        pageSize: 10,
        count: 0
      },
      userId: '',
      title: '',
      cont: '',
      editText: '',
      sriptLibraryPicker: true,
      editShow: false,
      groupList: [],
      groupId: '',
      searchTitle: '',
      curentData: {}
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    })
  },
  watch: {},
  async created() {
    this.getALLGroup()
    this.getSriptLibrary()
  },
  mounted() {},
  // 方法集合
  methods: {
    setListData(listData) {
      this.list = listData
    },
    resetData() {
      this.getSriptLibrary()
    },
    getSriptLibrary() {
      this.$store.commit('setLoading', true)
      const parmas = {
        pageIndex: 1,
        pageSize: 100
      }
      getGroupDetail(parmas).then((res) => {
        this.loading = false
        const data = res.data.dataList
        if (data && data.length > 0) {
          this.pages.count = res.data.page.totalCount || 0
          this.list = data
          this.list.forEach((item) => {
            item.sendTimes = item.sendTimes ? item.sendTimes : 0
          })
        }
        else {
          this.pages.count = 0
        }

        if (
          Number(this.pages.count) <=
          this.pages.pageSize * this.pages.pageIndex
        ) {
          this.finished = true
        }
        else {
          this.finished = false // false 只有设置为未结束才会继续加载
          this.pages.pageIndex++
        }
        this.$store.commit('setLoading', false)
      })
    },
    // 获取全部话术分组
    getALLGroup() {
      const params = {
        catId: '5'
      }
      getALLGroupList(params).then(res => {
        if (res.code === 0) {
          this.groupList = res.data
          this.$refs.sarchBar.groupList = res.data
        }
      })
    },
    initFilter() {
      const filter = {}
      filter.pageIndex = this.pages.pageIndex
      filter.pageSize = this.pages.pageSize
      filter.userId = this.userId
      filter.title = this.title
      return filter
    },
    doclear() {
      this.searchTitle = ''
    },
    onSearch(val) {
      this.searchTitle = val
    },
    changeArrow(id) {
      this.groupId = this.groupId === id ? '' : id
    },
    editSriptLibrary(item) {
      this.editShow = true
      this.curentData = item
      this.cont = item.content
    },
    async sendChatMessage() {
      const res = await wxSDK.sendChatMessage('text', {
        content: this.cont
      })
      console.log(res, '999999999')
      const pointParam = await this.creatPointParam()
      if (res === true) {
      // 上报埋点
        shareSpeechPoint({
          ...pointParam,
          // id: this.curentData.id
          scriptId: this.curentData.id,
          scriptName: this.curentData.questionTitle

        })
      }
      this.editShow = false
    },
    // 生成埋点公共参数
    async creatPointParam() {
      const statisticsId = uuidv4()
      let customerId = ''
      const sendChannel = await wxSDK.getContext()
      if (sendChannel === 'single_chat_tools') {
        customerId = await wxSDK.getCurExternalContact()
      }
      else if (sendChannel === 'group_chat_tools') {
        customerId = await wxSDK.getCurExternalChat()
      }
      const pointParam = { customerId, sendChannel, statisticsId }
      return pointParam
    }
  }
}
</script>

<style lang="less" scoped>
.van-search {
  border: 1px solid #808080;
  padding: 0;
}
.van-search__content {
  background-color: #fff;
}
.van-cell {
  padding: 0;
}
.title {
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  font-size: 16px;
  font-family: DFPKingGothicGB-Medium, DFPKingGothicGB;
  font-weight: 500;
  color: #000000;
  padding: 12px 16px;
  box-sizing: border-box;
}

.body {
  padding: 0;
  overflow-y: auto;
}

.search {
  height: 32px;
  background: #ffffff;
  border: 1px solid #808080;
}

.lt-icon {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.j-s {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.name {
  margin-top: 20px;
}
.text {
  font-size: 14px;
  font-family: DFPKingGothicGB-Regular, DFPKingGothicGB;
  font-weight: 400;
  color: #333333;
  margin-left: 6px;
}
.edit_cont {
  padding: 20px;
}
.van-button--normal {
  padding: 0 50px;
}

.list{
  padding: 16px;
  .j-s {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .img_l {
    margin-top: 20px;
  }
  .img_l img {
    padding-top: 4px;
    width: 16px;
  }
  .name {
    margin-top: 20px;
  }
  .edit_img {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .text {
    font-size: 14px;
    font-family: DFPKingGothicGB-Regular, DFPKingGothicGB;
    font-weight: 400;
    color: #333333;
    margin-left: 6px;
  }

  .list_cont {
    // margin-left: 30px;
    align-items: flex-start !important;
    display: flex;
  }

  .rt-cont {
    margin-left: 10px;
    margin-top: 20px;
    width: 100%;
    position: relative;
  }

  .rt-cont .rt-title {
    font-size: 14px;
    font-family: DFPKingGothicGB-Medium, DFPKingGothicGB;
    font-weight: 500;
    color: #000000;
    margin-right: 20px;
  }

  .rt-cont .rt-text {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: DFPKingGothicGB-Light, DFPKingGothicGB;
    font-weight: 300;
    color: #333333;
    margin-top: 10px;
    word-break: break-all;
    .rt-content{
      flex: 1;
    }
    .tage-box{
      width: 60px;
    }
  }
  .tag2{
    background-color: #e99d42;
        // height: 20px;
        border-radius: 10%;
  }
  .edit_cont {
    padding: 20px;
  }
  .van-button--normal {
    padding: 0 50px;
  }
}
</style>
